import React, { useEffect } from "react";
import styled from "styled-components"
import { motion } from "framer-motion"
import { Container, Row, Col } from "react-bootstrap"

import { useUIState } from "../contexts/UIStateContext"
import { useNavState } from "../contexts/NavStateContext"

import Layout from "../components/Layout/Layout"
import ArrowNav from "../components/ArrowNav/ArrowNav"
import Seo from "../components/Seo/Seo"


const View = styled(Container)`
    padding-top: 50px;
    padding-bottom: 1.5rem;
    min-height: calc(100vh - ${props => props.offset}px - 1.5rem);     
    padding-left: 0;
    padding-right: 0;

    @media (max-width: 768px) {
        min-height: calc(100vh - ${props => props.mobileoffset}px - 1.5rem);
      }
    
    p, a, span{
        font-weight: regular;
        font-size: 0.8rem;
    }

    h2{
        font-weight: bold;
        font-size: 0.8rem;
        margin-bottom: 1.3rem
    }

    h4{
        font-weight: bold;
        font-size: 0.8rem;
    }
`
const Section = styled.section`
    padding: 0px 12px;
`

const Datenschutz = ({ location }) => {
    const { mobileoffset, totalOffset } = useUIState()
    const { setDsgvoOpen } = useNavState()

    useEffect(() => {
        setDsgvoOpen(true)
    }, [])

    return(
        <Layout location={location} activeProjectType="dsgvo" backgroundColor="bgCreme">
            <View fluid className="overflow-hidden px-0" offset={totalOffset} mobileoffset={mobileoffset}>
                <Seo title="Datenschutz" type="WebPage" />
                <Section 
                    id="datenschutz"
                    as={motion.div}
                    initial={{
                        opacity: 0
                    }}
                    animate={{
                        opacity: 1
                    }}
                    exit={{
                        opacity: 0
                    }}
                    transition={{
                        duration: 0.3
                    }}>
                    <Row>
                        <Col lg={{ span: 7, offset: 3 }} xl={{ span: 6, offset: 2 }}>
                            <div className="mb-5">
                                <h2>Datenschutzerklärung</h2>
                                <p>Die Betreiber dieser Seiten nehmen den Schutz Ihrer persönlichen Daten sehr ernst. Wir behandeln Ihre 
                                    personenbezogenen Daten vertraulich und entsprechend der gesetzlichen Datenschutz-Grundverordnung sowie dieser Datenschutzerklärung.</p>
                                <p>Die Nutzung unserer Webseite ist ohne Angabe personenbezogener Daten möglich. Wir weisen darauf hin, dass
                                die Datenübertragung im Internet,z.B. bei der Kommunikation per E-Mail, Sicherheitslücken aufweisen kann. Ein
                                lückenloser Schutz der Daten vor dem Zugriff durch Dritte ist nicht möglich.</p>
                                <p>Der Nutzung von im Rahmen der Impressumspflicht veröffentlichten Kontaktdaten durch Dritte wird hiermit
                                ausdrücklich widersprochen. Die Betreiber der Seiten behalten sich ausdrücklich rechtliche Schritte im Falle der
                                unverlangten Zusendung von Werbeinformationen, etwa durch Spam-Mails, vor.</p>
                            </div>
                            <div className="my-5">
                                <h2>Erhebung, Verarbeitung und Nutzung personenbezogener Daten</h2>
                                <p>Wir speichern personenbezogene Daten nur, wenn sie uns diese beispielsweise in einer E-Mail selbst mitteilen.
                                Dies geschieht in der Regel im Rahmen einer Anfrage, bzw. eines Auftrags und ist Grundlage für die Erbringung unserer 
                                Leistungen. Sie haben jederzeit das Recht auf unentgeltliche Auskunft über Ihre gespeicherten personenbezogenen Daten sowie ein Recht auf Berichtigung, Sperrung oder Löschung dieser Daten. Wenn Sie weitere Fragen zur Erhebung, Verarbeitung oder Nutzung Ihrer personenbezogenen Daten haben, können Sie
                                sich jederzeit über die im Impressum angegeben Adresse des Webseitenbetreibers an uns wenden.</p>
                            </div>
                            <div className="my-5">
                                <h2>Cookies</h2>
                                <p>Die Internetseite verwendet keine Cookies.</p>
                            </div>
                            <div className="my-5">
                                <h2>Server-Log-Files</h2>
                                <p>Der Provider der Seiten erhebt und speichert automatisch Informationen in so genannten Server-Log-Files, die
                                Ihr Browser automatisch übermittelt. Dies sind: Browsertyp/ Browserversion, verwendetes Betriebssystem,
                                Referrer URL, Hostname des zugreifenden Rechners, Uhrzeit der Serveranfrage</p>
                                <p>Diese Daten sind nicht bestimmten Personen zuordenbar. Eine Zusammenführung dieser Daten mit anderen
                                Datenquellen wird nicht vorgenommen. Wir behalten uns vor, diese Daten nachträglich zu prüfen, wenn uns
                                konkrete Anhaltspunkte für eine rechtswidrige Nutzung bekannt werden.</p>
                            </div>
                            <div className="my-5">
                                <h4>Diese Website verwendet kein Kontaktformular und bietet keine Registrierung an.</h4>
                            </div>
                        </Col>
                    </Row>
                    <ArrowNav location={location} bgColor="bgCreme" />
                </Section>
            </View>
        </Layout>
    )
}

export default Datenschutz